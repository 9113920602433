import { debounce } from './utils';

window.addEventListener('load', () => {
  const stickyListItems = document.querySelectorAll('.sticky-category-item');
  const stickyCategoriesContainer = document.querySelector('.sticky-categories');
  const header = document.getElementById('header');

  const containerStyles = getComputedStyle(stickyCategoriesContainer);

  let isMobile = containerStyles.getPropertyValue('flex-direction') === 'row';

  function onResize() {
    isMobile = containerStyles.getPropertyValue('flex-direction') === 'row';
    stickyCategoriesContainer.setAttribute('style', `top: 0; z-index: 1000;`);
    stickyListItems.forEach((item) => {
      item.setAttribute(
        'style',
        `min-height: ${stickyCategoriesContainer.getBoundingClientRect().height}px`,
      );
    });
  }

  document.addEventListener('resize', onResize);
  onResize();

  const categoriesChildren = Array.from(stickyCategoriesContainer.children);

  /**
   * Accordion click callback
   * @param {Element} category
   */
  function scrollMobileCategories(category) {
    if (isMobile) {
      stickyCategoriesContainer.scrollTo({
        left: category.offsetLeft,
        behavior: 'smooth',
      });
    }
  }

  const debouncedCategoriesScroll = debounce(scrollMobileCategories, 100);

  categoriesChildren.forEach((category) => {
    category.addEventListener('click', () => {
      const categoryClass = [...category.classList].find((cls) => /^category-/.test(cls));

      debouncedCategoriesScroll(category);

      // On category click find first item that belongs to that category and scroll to it
      for (let i = 0; i < stickyListItems.length; i++) {
        const item = stickyListItems[i];
        if (item.classList.contains(categoryClass)) {
          const top =
            item.getBoundingClientRect().top +
            window.scrollY -
            (header.clientHeight + (isMobile ? stickyCategoriesContainer.clientHeight : 1));

          window.scrollTo({
            top: Math.round(top),
            behavior: 'smooth',
          });
          break;
        }
      }
    });
  });

  document.addEventListener('scroll', () => {
    stickyListItems.forEach((item) => {
      // On scroll check if list item is on the same level with categories
      if (
        item.getBoundingClientRect().top - 150 <=
        stickyCategoriesContainer.getBoundingClientRect().top
      ) {
        // If it is give it active class
        item.classList.add('sticky-category-item--active');

        // Check which category class does that item have to style category element appropriately
        const listItemCategoryClass = [...item.classList].find((cls) => /^category-/.test(cls));

        // Loop through categories and check if current list element belongs to it
        categoriesChildren.forEach((category) => {
          // If it belongs to it mark that category as active, if not remove active class
          if (category.classList.contains(listItemCategoryClass)) {
            category.classList.add('sticky-category--active');
            debouncedCategoriesScroll(category);
          } else {
            category.classList.remove('sticky-category--active');
          }
        });
      } else {
        item.classList.remove('sticky-category-item--active');
      }
    });
  });
});